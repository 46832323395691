
<div class="container">
<!-- HEADING -->
<h1>{{ (page | async)?.heading }}</h1>

<!-- DIRECTOR 1 -->
<div class="directorRow row">
  <!-- IMAGE -->
  <div class="directorImage col-sm-4">
    <img class="profileImage" [src]="profileImage1" alt="" />
  </div>
  <!-- BIOG -->
  <div class="directorContent col-sm-8">
    <div class="directorName" [innerHTML]="(page | async)?.director1name | noSanitize"></div>
    <div class="directorTitle" [innerHTML]="(page | async)?.director1title | noSanitize"></div>
    <p [innerHTML]="(page | async)?.director1biog | noSanitize"></p>
  </div>
</div>

<hr>

<!-- DIRECTOR 2 -->
<div class="directorRow row">
  <!-- IMAGE -->
  <div class="directorImage col-sm-4">
    <img class="profileImage" [src]="profileImage2" alt="" />
  </div>
  <!-- BIOG -->
  <div class="directorContent col-sm-8">
    <div class="directorName" [innerHTML]="(page | async)?.director2name | noSanitize"></div>
    <div class="directorTitle" [innerHTML]="(page | async)?.director2title | noSanitize"></div>
    <p [innerHTML]="(page | async)?.director2biog | noSanitize"></p>
  </div>
</div>

<hr>

<!-- DIRECTOR 3 -->
<div class="directorRow row">
  <!-- IMAGE -->
  <div class="directorImage col-sm-4">
    <img class="profileImage" [src]="profileImage3" alt="" />
  </div>
  <!-- BIOG -->
  <div class="directorContent col-sm-8">
    <div class="directorName" [innerHTML]="(page | async)?.director3name | noSanitize"></div>
    <div class="directorTitle" [innerHTML]="(page | async)?.director3title | noSanitize"></div>
    <p [innerHTML]="(page | async)?.director3biog | noSanitize"></p>
  </div>
</div>



</div>
