import { Component, Input, Inject } from '@angular/core';
import { Project } from 'src/app/models/project';
import { ProjectsService } from 'src/app/services/projects.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() project: Project;
  @Input() projectIsSelected: boolean;
  // projectRef: AngularFirestoreDocument<Project>;
  // project$: Observable<Project>;
  admin: Observable<boolean>;
  youtubeURL;

  constructor(
    private router: Router,
    private projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.youtubeURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      data.youtubeURL
    );
    console.log('YOUTUBE-URL', this.youtubeURL);
  }



  hasCommas(value: string) {
    const commas = value.indexOf(',') > -1;
    return commas;
  }

  handleEdit(projectId: string) {
    this.router.navigate(['public/admin', projectId]);
  }

  handleDelete(projectId: string) {
    this.projectsService.deleteProject(projectId);
  }
}
