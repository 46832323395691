import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from '../public/home-page/home-page.component';
import { AboutPageComponent } from '../public/about-page/about-page.component';
import { MediaPageComponent } from '../public/media-page/media-page.component';
import { ProjectsPageComponent } from '../public/projects-page/projects-page.component';
import { ContactPageComponent } from '../public/contact-page/contact-page.component';
import { LoginComponent } from '../public/login/login.component';
import { NoAccessComponent } from '../public/no-access/no-access.component';
import { PublicComponent } from './public.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      { path: 'home', component: HomePageComponent },
      { path: 'about', component: AboutPageComponent },
      { path: 'media', component: MediaPageComponent },
      { path: 'projects', component: ProjectsPageComponent },
      { path: 'contact', component: ContactPageComponent },

      { path: 'login', component: LoginComponent },
      { path: 'no-access', component: NoAccessComponent },
      // {path: '', component: UnderConstructionPageComponent},
      {
        // Lazyload the admin module
        path: 'admin',
        loadChildren: () =>
          import('../admin/admin.module').then((mod) => mod.AdminModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule {}
