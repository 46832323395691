import { SnackService } from './../services/snack.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  userIsAdmin: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private snack: SnackService
  ) {}

  canActivate(): Observable<boolean> {
    // If user logged in and isAdmin
    return this.userService.user$.pipe(
      map(user => (user && user.roles.admin ? true : false)),
      tap(isAdmin => {
        if (!isAdmin) {
          this.snack.denyAccess();
          console.log('Access denied - Admins only');
          this.router.navigateByUrl('/no-access');
          return isAdmin;
        }
      })
    );
  }
}
