<mat-dialog-actions align="end">
  <div *ngIf="projectIsSelected && admin | async" class="crud-items">
    <edit-button (edit)="handleEdit(project.pid)"></edit-button>
    <delete-button (delete)="handleDelete(project.pid)"></delete-button>
  </div>
  <div class="close" mat-dialog-close>&#10006;</div>
</mat-dialog-actions>
<div class="container">
  <iframe
    class="responsive-iframe e2e-trusted-url"
    [src]="youtubeURL"
  ></iframe>
</div>
<div>
  <br />
  <div class="description">
    <h3>
      {{ data.title }}
    </h3>
    <p>
      {{ data.synopsis }}
    </p>
    <p>
      <strong>Director<span *ngIf="hasCommas(data.directors)">s</span>:</strong><br />
      {{ data.directors }}
    </p>
    <p>
      <strong>Writer<span *ngIf="hasCommas(data.writers)">s</span>:</strong><br />
      {{ data.writers }}
    </p>
    <p>
      <strong>Star<span *ngIf="hasCommas(data.stars)">s</span>:</strong><br />
      {{ data.stars }}
    </p>
  </div>
</div>
