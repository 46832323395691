<video
  autoplay
  loop
  src="https://firebasestorage.googleapis.com/v0/b/strategic-dev.appspot.com/o/sunrise-from-space-30.mp4?alt=media&token=e1af70b3-3260-42e3-b013-c872bc4abd45"
  type="video/mp4"
></video>
<div class="outer">
  <div class="middle">
    <h1>STRATEGIC FILM DISTRIBUTION</h1>

    <div class="inner">
      <div class="enter" routerLink="public/home"  (click)="playAudio()" (click)="goToHome()" >
        <img
          src="../../assets/images/shutter-icon-white.svg"
          alt="Strategic Film Distribution Shutter Icon"
        />
        </div>
      <p class="missionStatement">
        <span class="quotes">&#8220;</span>
        Changing the landscape of Australian Film Distribution, through our online
        platform. Connecting to over a billion consumers worldwide, to
        Strategically Market your film, for a more targeted approach
        <span class="quotes">&#8221;</span>
      </p>
    </div>
  </div>
</div>
