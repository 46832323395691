<mat-sidenav-container class="sidenav-container">
  <!-- SIDENAV -->
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false"
  >
    <mat-toolbar #header
      >Menu
      <mat-icon
        class="close"
        aria-hidden="false"
        aria-label="Example close icon"
        (click)="drawer.close()"
        >close</mat-icon
      ></mat-toolbar
    >
    <mat-nav-list>
      <a
        mat-list-item
        routerLink="/public/home"
        routerLinkActive="active"
        (click)="drawer.close()"
        >Home</a
      >
      <a
        mat-list-item
        routerLink="/public/about"
        routerLinkActive="active"
        (click)="drawer.close()"
        >About</a
      >
      <a
        mat-list-item
        routerLink="/public/media"
        routerLinkActive="active"
        (click)="drawer.close()"
        >Media</a
      >
      <a
        mat-list-item
        routerLink="/public/projects"
        routerLinkActive="active"
        (click)="drawer.close()"
        >Projects</a
      >
      <a
        mat-list-item
        routerLink="/public/contact"
        routerLinkActive="active"
        (click)="drawer.close()"
        >Contact</a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- TOP TOOLBAR-->
    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <span *ngIf="isHandset$ | async" class="fill-space"></span>

      <span class="logo" routerLink="/">
        <img src="../../assets/images/shutter-icon-white.svg" />
        <span class="logoWords">STRATEGIC FILM DISTRIBUTION</span>
      </span>
      <span class="fill-space"></span>
      <div *ngIf="!(isHandset$ | async)">
        <a mat-button routerLink="/public/home">Home</a>
        <a mat-button routerLink="/public/about">About</a>
        <a mat-button routerLink="/public/media">Media</a>
        <a mat-button routerLink="/public/projects">Projects</a>
        <a mat-button routerLink="/public/contact">Contact</a>
      </div>

      <!-- DROPDOWN MENU -->
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon class="sfd-purple">vpn_key</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <a mat-menu-item *ngIf="admin | async" routerLink="admin">Admin</a>
        <hr />
        <a *ngIf="!(user$ | async)" mat-menu-item routerLink="login">Login</a>
        <a
          *ngIf="user$ | async"
          mat-menu-item
          routerLink="login"
          (click)="logout()"
          >Logout</a
        >
      </mat-menu>
    </mat-toolbar>
    <!-- TRANSCLUSION -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
