import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminAuthGuard } from '../admin/admin-auth.guard';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { ProjectFormComponent } from './projects-edit/project-form/project-form.component';
import { ProjectsEditListComponent } from './projects-edit/projects-edit-list/projects-edit-list.component';

const adminRoutes: Routes = [
  {
    path: '',
    component: AdminPortalComponent,
    canActivate: [AdminAuthGuard],
    children: [
      {
        path: 'add-new-project',
        component: ProjectFormComponent
      },
      {
        path: 'edit/:id',
        component: ProjectFormComponent
      },
      {
        path: '',
        component: ProjectsEditListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
