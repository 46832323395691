import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { User } from '../models/user';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  fireUser$: Observable<firebase.User>;
  user$: Observable<User>;
  name: string;
  redirectUrl: string;
  file: File;
  downloadURL: Observable<string>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) {
    //// Get auth data, then get firestore user document OR return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // If user exists, login ...
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          // Otherwise, not logged in ...
        } else {
          return of(null);
        }
      })
    );
  }

  // appUser$ returns the currently logged in user object without a uid
  // - Not the firebase auth object
  get appUser$(): Observable<User> {
    return this.fireUser$.pipe(
      switchMap(user => this.getUser(user.uid).valueChanges())
    );
  }

  // Returns the the currently logged in user object using a uid
  public getUser(uid: string): AngularFirestoreDocument<User> {
    return this.afs.doc(`users/${uid}`);
  }

  public updateUserData(user: User) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      roles: {
        admin: false
      },
      firstName: '',
      lastName: '',
      mobile: null,
      preferedName: ''
    };

    return userRef.set(data, { merge: true });
  }

  ///// Role-based Authorization //////

  isAdmin(): Observable<boolean> {
    // If user logged in and is an Admin
    return this.user$.pipe(
      map(appUser => (appUser && appUser.roles.admin ? true : false))
    );
  }


}
