import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit {
  clicked = false;
  constructor(private router: Router) {}

  ngOnInit() {
    // Shutter only clicks after 20 seconds if it hasn't already clicked
    setTimeout(() => {
      this.router.navigate(['public/home']);
      if (!this.clicked) {
        this.playAudio();
      }
    }, 20000);
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../assets/sounds/shutter-click.wav';
    audio.load();
    audio.play();
    this.clicked = true;
  }

  goToHome() {
    this.clicked = true;
    this.router.navigate(['public/home']);
  }
}
