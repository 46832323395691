<div class="container" *ngIf="page | async">
  <h1>{{ (page | async)?.heading }}</h1>

  <div class="address">
    <p class="cms" [innerHTML]="(page | async)?.content | noSanitize"></p>
    PO BOX 2378<br />
    BRIGHTON NORTH<br />
    VICTORIA 3186<br />
    AUSTRALIA<br />
    M +61 414 767 796<br />
  </div>
  <hr />
  <!-- CONTACT FORM -->

  <form [formGroup]="contactForm">
    <!-- FIRST NAME -->
    <mat-form-field appearance="standard">
      <mat-label>First Name</mat-label>
      <input
        #firstName
        type="text"
        formControlName="firstName"
        required
        matInput
        placeholder="Enter your first name"
      />
    </mat-form-field>

    <!-- LAST NAME -->
    <mat-form-field appearance="standard">
      <mat-label>Last Name</mat-label>
      <input
        #lastName
        type="text"
        formControlName="lastName"
        required
        matInput
        placeholder="Enter your last name"
      />
    </mat-form-field>

    <!-- MOBILE -->
    <mat-form-field appearance="standard">
      <mat-label>Mobile</mat-label>
      <input
        #mobile
        type="tel"
        formControlName="mobile"
        required
        matInput
        placeholder="Enter your mobile number"
      />
    </mat-form-field>

    <!-- EMAIL -->
    <mat-form-field appearance="standard">
      <mat-label>Email</mat-label>
      <input
        #email
        type="email"
        formControlName="email"
        required
        matInput
        placeholder="Enter your email address"
      />
    </mat-form-field>

    <!-- MESSAGE -->
    <mat-form-field appearance="standard">
      <mat-label>Message</mat-label>
      <textarea
        #message
        matInput
        type="textarea"
        formControlName="message"
        required
        placeholder="Your message..."
      ></textarea>
    </mat-form-field>

    <button
      id="toggle"
      mat-raised-button
      type="button"
      (click)="submitForm()"
      [disabled]="!contactForm.valid || submitted"
    >
      Send
    </button>
    <!-- Animated Circle Submission Confirmation -->
    <div [ngClass]="{ 'circle-loader': submitted, 'load-complete': delivered }">
      <div
        class="checkmark draw"
        [ngStyle]="{ display: delivered ? 'block' : 'none' }"
      ></div>
    </div>
  </form>
</div>


