<div class="form-container">
  <form [formGroup]="pageForm">
    <!-- Headline -->
    <label for="heading">Heading</label>
    <input
      type="text"
      formControlName="heading"
      placeholder="Enter heading.."
    />

    <!-- Content for page above projects -->
    <label for="content">Content</label>
    <quill-editor
      formControlName="content"
      placeholder="Enter Page Content here..."
    ></quill-editor>

    <div class="buttons">
      <!-- Submit Button for content-->
      <div class="spacer"></div>
      <button
        type="button"
        mat-raised-button
        color="accent"
        (click)="onSubmit()"
      >
        Save
      </button>

      <!-- Cancel Button for content -->
      <button class="cancel" mat-raised-button color="warn" (click)="back()">
        Back
      </button>
      <div class="spacer"></div>
    </div>
  </form>
  <hr />

  <div class="newProject">
    <div class="spacer"></div>
    <button
      *ngIf="showButton"
      mat-raised-button
      color="primary"
      [routerLink]="['add-new-project']"
    >
      Add new project
    </button>
    <div class="spacer"></div>
  </div>

  <router-outlet></router-outlet>
</div>
