import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements AfterViewInit {
  @ViewChild('spaceVideo') spaceVideo: ElementRef;
  pausedState: string;
  paused: boolean;
  video: any;

  constructor() {
    this.paused = false;
    this.pausedState = 'Pause';
  }

  ngAfterViewInit(): void {
    this.video = this.spaceVideo.nativeElement;
    this.video.playbackRate = 1.0;
  }

  pause() {
    if (this.paused) {
      this.paused = false;
      this.pausedState = 'Pause';
      this.video.play();
    } else {
      this.paused = true;
      this.video.pause();
      this.pausedState = 'Play';
    }
  }
}
