import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/storage';

// Modules
import { AdminRoutingModule } from './admin-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';

// Quill
import { QuillModule } from 'ngx-quill';

// Services
import { UploadImageService } from '../services/upload-image.service';
import { AdminAuthGuard } from './admin-auth.guard';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

// Components
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { AboutEditComponent } from './about-edit/about-edit.component';
import { HomeEditComponent } from './home-edit/home-edit.component';
import { MediaEditComponent } from './media-edit/media-edit.component';
import { ProjectsEditComponent } from './projects-edit/projects-edit.component';
import { ProjectsEditListComponent } from './projects-edit/projects-edit-list/projects-edit-list.component';
import { ProjectFormComponent } from './projects-edit/project-form/project-form.component';
import { ProjectThumbnailComponent } from './projects-edit/project-thumbnail/project-thumbnail.component';
import { DataService } from '../services/data.service';


@NgModule({
  declarations: [
    AdminPortalComponent,
    HomeEditComponent,
    AboutEditComponent,
    MediaEditComponent,
    ProjectsEditComponent,
    ProjectsEditListComponent,
    ProjectFormComponent,
    ProjectThumbnailComponent,
    ContactEditComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    QuillModule.forRoot({
      modules: {
        blotFormatter: {
          modules: ['Resize', 'DisplaySize', 'Toolbar']
        },
        htmlEditButton: {}
      }
    })
  ],
  providers: [
    AuthService,
    UserService,
    AngularFireStorage,
    UploadImageService,
    AdminAuthGuard,
    DataService
  ]
})
export class AdminModule {}
