<shell>
  <video
    #spaceVideo
    autoplay
    muted
    loop
    class="spaceVideo"
    poster="../../assets/images/sunrise-from-space-poster.jpg"
  >
    <source
      src="https://firebasestorage.googleapis.com/v0/b/strategic-dev.appspot.com/o/sunrise-from-space-30.mp4?alt=media&token=e1af70b3-3260-42e3-b013-c872bc4abd45"
      type="video/mp4"
    />
  </video>
  <router-outlet></router-outlet>

  <!-- PAUSE BUTTON -->
  <div class="container">
    <div style="text-align:center;">
      <button mat-button #pauseButton class="pauseButton" (click)="pause()">
        {{ pausedState }}
      </button>
    </div>
  </div>
</shell>
