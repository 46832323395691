import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  samePage: string;

  constructor(private snackBar: MatSnackBar, private router: Router) {}

  // Not logged in
  authError() {
    this.snackBar.open('You must be logged in', 'OK', {
      duration: 3000
    });

    return this.snackBar._openedSnackBarRef
      .onAction()
      .pipe(tap(_ => this.router.navigate(['/login'])))
      .subscribe();
  }

  // Not authorised to access
  denyAccess() {
    this.snackBar.open(
      'Access Denied. \
       You are not authorised to access the Admin section.',
      'OK',
      {
        duration: 3000
      }
    );

    return this.snackBar._openedSnackBarRef
      .onAction()
      .pipe(tap(_ => this.router.navigate(['/login'])))
      .subscribe();
  }

  // Pass any message with this snack bar function
  passMessage(message, duration?: number, route?: string) {
    this.samePage = this.router.url;

    this.snackBar.open(`${message}`, 'OK', {
      duration: duration || 3000
    });
    setTimeout(() => {
      this.router.navigate([route || this.samePage]);
    }, duration);

    return this.snackBar._openedSnackBarRef
      .onAction()
      .pipe(tap(_ => this.router.navigate([route || this.samePage ])))
      .subscribe();
  }
}
