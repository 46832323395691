import { Component, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent {
  @Output() edit = new EventEmitter();

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    event.stopPropagation();
  }

  editItem() {
    this.edit.emit('edit');
  }
}
