import { ProjectsService } from 'src/app/services/projects.service';
import { DataService } from 'src/app/services/data.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Project } from 'src/app/models/project';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'project-thumbnail',
  templateUrl: './project-thumbnail.component.html',
  styleUrls: ['./project-thumbnail.component.scss']
})
export class ProjectThumbnailComponent implements OnInit {
  @Output() edit = new EventEmitter<Project>();
  @Input() project: Project;
  @Input() projectIsSelected: boolean;
  projectRef: AngularFirestoreDocument<Project>;
  project$: Observable<Project>;
  admin: Observable<boolean>;

  constructor(
    private dataService: DataService,
    private projectService: ProjectsService
  ) {}

  ngOnInit(): void {}

  editProject(id) {
    this.dataService.nextData(id);
    this.edit.emit(id);
  }

  handleDelete(id) {
    this.projectService.deleteProject(id);
  }
}
