import { BehaviorSubject } from 'rxjs';
import { Injectable, Input } from '@angular/core';

@Injectable()
export class DataService {
  @Input() inputData: any;

  private data: BehaviorSubject<any> = new BehaviorSubject<any>(this.inputData);

  nextData(data?: any) {
    this.data.next(data);
  }
}
