import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../../services/pages.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Page } from '../../models/page';
import * as QuillNamespace from 'quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { htmlEditButton } from 'quill-html-edit-button';
// import { VideoResize } from 'quill-video-resize-module';

@Component({
  selector: 'contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {
  page: Observable<Page>;
  pageId: string;
  Quill: any = QuillNamespace;
  quill: any;

  pageForm = this.fb.group({
    heading: [''],
    content: ['']
  });

  constructor(
    private fb: FormBuilder,
    private pagesService: PagesService,
    private location: Location
  ) {
    this.Quill.register('modules/blotFormatter', BlotFormatter);
    this.Quill.register('modules/htmlEditButton', htmlEditButton);
    // this.Quill.register('modules/videoResize', VideoResize);
  }

  async ngOnInit() {
    // Populate form with existing page data
    this.page = this.pagesService.getPage('contact');
    this.page.subscribe(page => {
      this.pageForm.get('heading').setValue(page.heading);
      this.pageForm.get('content').setValue(page.content);
    });
  }

  back() {
    this.location.back();
  }

  onSubmit() {
    this.pagesService.updatePage('contact', this.pageForm.value);
  }

  getPage(pageId: string) {
    this.pagesService.getPage(pageId);
    return this.page;
  }

  editPage(pageId: string, page: Page) {
    this.pagesService.updatePage(pageId, page);
  }
}
