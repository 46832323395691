import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from './../../../services/user.service';
import { Project } from 'src/app/models/project';
import { Observable } from 'rxjs';


// This component renders all ProjectRows and stores the currently selected Project
@Component({
  selector: 'projects-page-list',
  templateUrl: './projects-page-list.component.html',
  styleUrls: ['./projects-page-list.component.scss']
})
export class ProjectsPageListComponent implements OnInit {
  // Where an array of projects is passed in
  @Input() projectsList: Observable<Project[]>;

  // This property will emit which project is selected
  @Output() projectSelected: EventEmitter<Project>;

  // This property contains the currently selected `Project`
  @Input() currentProject: Project;
  @Input() projectIsSelected: boolean;

  selected: boolean;

  constructor(private userService: UserService) {
    this.projectSelected = new EventEmitter();
  }

  ngOnInit() {
    this.selected = false;
  }

  clicked(project: Project): void {
    if (this.userService.isAdmin) {
      this.projectSelected.emit(project);
    }
  }

}
