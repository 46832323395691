import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectsService } from '../../../services/projects.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { Project } from '../../../models/project';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, finalize } from 'rxjs/operators';
import { AngularFireUploadTask } from '@angular/fire/storage/task';

@Component({
  selector: 'project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit, OnDestroy {
  @Output() showProjects = new EventEmitter<any>();
  @Input() project: Project;
  project$: Observable<Project>;
  userIsAdmin: boolean;
  admin: Observable<boolean>;
  private ngUnsubscribe = new Subject();
  subscription: Subscription = null;
  showForm: boolean;
  progressValue: number;
  posterImage: any = '../../../assets/images/poster-placeholder.jpg';
  projectID: any;
  selectedImage: any;
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  projectForm = this.fb.group({
    pid: '',
    poster: this.posterImage,
    youtubeURL: ['', Validators.required],
    title: ['', Validators.required],
    synopsis: ['', Validators.required],
    directors: ['', Validators.required],
    writers: ['', Validators.required],
    stars: ['', Validators.required],
    date: [new Date()]
  });

  constructor(
    private fb: FormBuilder,
    private projectsService: ProjectsService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.admin = this.userService.isAdmin();
    this.progressValue = this.projectsService.progressUploader;
  }

  async ngOnInit() {
    // If there a ProjectID in the URL, this is an Edit
    if (this.route.snapshot.paramMap.get('id')) {
      this.project$ = this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.projectsService.getProject(params.get('id'))
        )
      );
    }

    if (this.project$) {
      this.project$.subscribe((project) => {
        this.posterImage = project.poster;
        this.projectForm.get('pid').setValue(project.pid);
        this.projectForm.get('youtubeURL').setValue(project.youtubeURL);
        this.projectForm.get('title').setValue(project.title);
        this.projectForm.get('poster').setValue(project.poster);
        this.projectForm.get('synopsis').setValue(project.synopsis);
        this.projectForm.get('directors').setValue(project.directors);
        this.projectForm.get('writers').setValue(project.writers);
        this.projectForm.get('stars').setValue(project.stars);
      });
    } else {
      this.project$ = null;
    }
  }

  async uploadPoster(event: any) {
    const selectedFiles: FileList = event.target.files;
    this.selectedImage = null || selectedFiles.item(0);
    if (this.selectedImage) {
      const [ref, task, percentage] = this.projectsService.uploadImage(this.selectedImage);
      this.percentage = percentage;
      const subscription = task.snapshotChanges().pipe(
        // The file's download URL
        finalize(async () => {
          const url = await ref.getDownloadURL().toPromise();
          console.log('URL: ', url);
          this.projectForm.get('poster').setValue(url);
          this.posterImage = url;
        })
      );
      this.subscription = subscription.subscribe((value) => {
        // this.percentage = task.percentageChanges();
      });
    }
  }

  onSubmit() {
    // If this submission is an update
    if (this.project$) {
      const correctedYoutubeURL = this.prepareYoutubeURL(
        this.projectForm.value.youtubeURL
      );
      this.projectForm.get('youtubeURL').setValue(correctedYoutubeURL);
      this.projectsService.updateProject(
        this.projectForm.value.pid,
        this.projectForm.value
      );
    } else {
      // This is adding a new project
      const correctedYoutubeURL = this.prepareYoutubeURL(
        this.projectForm.value.youtubeURL
      );
      this.projectForm.get('youtubeURL').setValue(correctedYoutubeURL);

      this.projectsService.createProject(this.projectForm.value);
    }
  }

  prepareYoutubeURL(url: string) {
    const standardYouTubePrefix = 'https://www.youtube.com/watch?v=';
    const desiredYouTubePrefix = 'https://www.youtube.com/embed/';

    // Check if YouTube prefix is already correct
    if (url.indexOf(desiredYouTubePrefix) === 0) {
      // // Check if SFD Suffix is already added, if so remove
      // if (
      //   url.endsWith(
      //     '?autoplay=1&origin=https://strategicfilmdistribution.com.au'
      //   )
      // ) {
      //   url.slice(0, -59);
      //   console.log('URL: ', url);
      return url;
      // }
    } else {
      // Get YouTube ID by removing standardYouTubePrefix path from the beginning to get id
      if (url.indexOf(standardYouTubePrefix) === 0) {
        url = url.slice(standardYouTubePrefix.length);
      }
      // Add correct YouTube URL prefix and suffix
      url =
        'https://www.youtube.com/embed/' +
        url +
        '?autoplay=1&origin=https://strategicfilmdistribution.com.au';
      return url;
    }
  }

  getProject(projectId: string) {
    this.projectsService.getProject(projectId);
    return this.project;
  }

  editProject(projectId: string, project: Project) {
    this.projectsService.updateProject(projectId, project);
  }

  ngOnDestroy() {
    if (this.subscription !== null) {
      this.subscription.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
