import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Project } from 'src/app/models/project';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'projects-edit-list',
  templateUrl: './projects-edit-list.component.html',
  styleUrls: ['./projects-edit-list.component.scss']
})
export class ProjectsEditListComponent implements OnInit {
  // Where an array of projects is passed in
  @Input() projectsList: Observable<Project[]>;

  // This property will emit which project is selected
  @Output() projectSelected: EventEmitter<Project>;

  // This property contains the currently selected `Project`
  @Input() currentProject: Project;
  @Input() projectIsSelected: boolean;

  selected: boolean;

  constructor(
    private userService: UserService,
    private dataService: DataService,
    private router: Router
  ) {
    this.projectSelected = new EventEmitter();
  }

  ngOnInit() {
    this.selected = false;
    this.dataService.inputData.subscribe(
      (data) => {
        this.projectsList = data;
        this.dataService.nextData(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  clicked(project: Project): void {
    if (this.userService.isAdmin) {
      this.router.navigate([`public/admin/edit/${project.pid}`]);
      this.projectSelected.emit(project);
    }
  }
}
