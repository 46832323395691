import { ProjectsService } from './../../services/projects.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PagesService } from './../../services/pages.service';
import { Page } from '../../models/page';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../shared/modal/modal.component';
import { Project } from '../../models/project';
import { UserService } from '../../services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent implements OnInit, OnDestroy {
  projects: Observable<Project[]>;
  admin: Observable<boolean>;
  page: Observable<Page>;
  clickedProject: Project;
  currentProject: Project;
  activeProject: Project;
  projectIsSelected: boolean;
  userIsAdmin: boolean;
  private ngUnsubscribe = new Subject();

  constructor(
    private pagesService: PagesService,
    private projectsService: ProjectsService,
    private userService: UserService,
    public dialog: MatDialog
  ) {
    this.page = this.pagesService.getPage('projects');
    this.projects = this.projectsService.getProjects();
    this.admin = this.userService.isAdmin();
  }

  ngOnInit(): void {
    this.currentProject = null;
    this.projectIsSelected = null;

    this.userService
    .isAdmin()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(a => (this.userIsAdmin = a.valueOf()));
  }

  projectIsSame() {
    // Checks if the project is the currently selected one
    if (this.currentProject === this.clickedProject) {
      return true;
    } else {
      return false;
    }
  }

  projectSelected(project: Project): void {
    // console.log('User is an Admin? ', this.userIsAdmin);
    if (this.userIsAdmin) {
      this.clickedProject = project;

      if (this.projectIsSame()) {
        this.currentProject = null;
        this.projectIsSelected = false;
      } else {
        if (project !== null) {
          this.clickedProject = project;
          this.currentProject = project;
          this.projectIsSelected = true;
        }
      }
      // console.log('Project selected: ', this.currentProject);
    } else {
      // console.log('User cannot edit projects');
    }
  }

  openDialog() {
    console.log('Dialogue Opened');
    this.dialog.open(ModalComponent);
  }

  ngOnDestroy() {
    // this.userService.isAdmin().subscribe(a => (this.userIsAdmin = a.valueOf())).unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
