
// import { UnderConstructionPageComponent } from './under-construction-page/under-construction-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [

  {
    // Lazyload the admin module
    path: 'splash',
    loadChildren: () =>
      import('./splash/splash.module').then((mod) => mod.SplashModule)
  },
  {
    // Lazyload the admin module
    path: 'public',
    loadChildren: () =>
      import('./public/public.module').then((mod) => mod.PublicModule)
  },
  { path: '', redirectTo: '/splash', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
