import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {
  fireUser$: Observable<firebase.User>;
  user$: Observable<User>;
  name: string;
  file: File;
  Math: Math;
  downloadURL: Observable<string>;
  progressUploader: number;
  private fbStorage: firebase.storage.Storage;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    //// Get auth data, then get firestore user document OR return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        // If user exists, login ...
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          // Otherwise, not logged in ...
        } else {
          return of(null);
        }
      })
    );

    this.fbStorage = this.storage.storage.app.storage();
  }


  public uploadImage1(file1: File) {
    this.fbStorage.ref(`${file1.name}`).put(file1);

    this.fbStorage
      .ref(`${file1.name}`)
      .getDownloadURL()
      .then(async (downloadURL) => {
        const imageRef: AngularFirestoreDocument<any> = this.afs.doc(
          `pages/about`
        );
        imageRef
          .set({ director1pic: downloadURL }, { merge: true })
          .then(() => console.log('Director 1 Image successfully updated!'))
          .catch((error) => console.error('Error updating imageURL: ', error));
      });
  }

  public uploadImage2(file2: File) {
    this.fbStorage.ref(`${file2.name}`).put(file2);

    this.fbStorage
      .ref(`${file2.name}`)
      .getDownloadURL()
      .then(async (downloadURL) => {
        const imageRef: AngularFirestoreDocument<any> = this.afs.doc(
          `pages/about`
        );
        imageRef
          .set({ director2pic: downloadURL }, { merge: true })
          .then(() => console.log('Director 2 Image successfully updated!'))
          .catch((error) => console.error('Error updating imageURL: ', error));
      });
  }

  public uploadImage3(file3: File) {
    this.fbStorage.ref(`${file3.name}`).put(file3);

    this.fbStorage
      .ref(`${file3.name}`)
      .getDownloadURL()
      .then(async (downloadURL) => {
        const imageRef: AngularFirestoreDocument<any> = this.afs.doc(
          `pages/about`
        );
        imageRef
          .set({ director3pic: downloadURL }, { merge: true })
          .then(() => console.log('Director 3 Image successfully updated!'))
          .catch((error) => console.error('Error updating imageURL: ', error));
      });
  }
}
