<div class="form-container">
  <form [formGroup]="pageForm">
    <!-- Headline -->

    <label for="heading">Heading</label>
    <input
      id="heading"
      type="text"
      formControlName="heading"
      placeholder="Enter heading.."
    />
    <hr />

    <!-- Directors -->
    <label for="director1name">Director 1</label>
    <input
      id="director1name"
      type="text"
      formControlName="director1name"
      placeholder="Enter 1st director's name"
    />
    <input
      id="director1title"
      type="text"
      formControlName="director1title"
      placeholder="Enter 1st director's title"
    />
    <div class="frame">
      <img
        class="profileImage"
        [src]="profileImage1"
        (click)="fileInput1.click()"
      />
      <input
        #fileInput1
        class="director1pic"
        (change)="uploadProfile1($event)"
        required
        accept=".jpg"
        type="file"
      />
    </div>

    <quill-editor
      type="text"
      formControlName="director1biog"
      placeholder="Enter 1st director's biog"
    >
    </quill-editor>
    <!-- Submit Button -->
    <button type="button" mat-raised-button color="accent" (click)="onSubmit()">
      Save
    </button>

    <!-- Cancel Button -->
    <button class="cancel" mat-raised-button color="warn" (click)="back()">
      Back
    </button>
    <hr />

    <label for="director2name">Director 2</label>
    <input
      id="director2name"
      type="text"
      formControlName="director2name"
      placeholder="Enter 2nd director's name"
    />
    <input
      id="director2title"
      type="text"
      formControlName="director2title"
      placeholder="Enter 2nd director's title"
    />
    <div class="frame">
      <img
        class="profileImage"
        [src]="profileImage2"
        (click)="fileInput2.click()"
      />
      <input
        #fileInput2
        class="director2pic"
        (change)="uploadProfile2($event)"
        required
        accept=".jpg"
        type="file"
      />
    </div>

    <quill-editor
      type="text"
      formControlName="director2biog"
      placeholder="Enter 2nd director's biog"
    >
    </quill-editor>
    <!-- Submit Button -->
    <button type="button" mat-raised-button color="accent" (click)="onSubmit()">
      Save
    </button>

    <!-- Cancel Button -->
    <button class="cancel" mat-raised-button color="warn" (click)="back()">
      Back
    </button>
    <hr />

    <label for="director3name">Director 3</label>
    <input
      id="director3name"
      type="text"
      formControlName="director3name"
      placeholder="Enter 3rd director's name"
    />
    <input
      id="director3title"
      type="text"
      formControlName="director3title"
      placeholder="Enter 3rd director's title"
    />
    <div class="frame">
      <img
        class="profileImage"
        [src]="profileImage3"
        (click)="fileInput3.click()"
      />
      <input
        #fileInput3
        class="director3pic"
        (change)="uploadProfile3($event)"
        required
        accept=".jpg"
        type="file"
      />
    </div>

    <quill-editor
      type="text"
      formControlName="director3biog"
      placeholder="Enter 3rd director's biog"
    >
    </quill-editor>

    <!-- Submit Button -->
    <button type="button" mat-raised-button color="accent" (click)="onSubmit()">
      Save
    </button>

    <!-- Cancel Button -->
    <button class="cancel" mat-raised-button color="warn" (click)="back()">
      Back
    </button>
    <hr />
  </form>
</div>
