<h1>{{ (page | async)?.heading }}</h1>
<div class="container" *ngIf="page | async">
  <p [innerHTML]="(page | async)?.content | noSanitize"></p>

  <projects-page-list
    [projectsList]="projects"
    [currentProject]="currentProject"
    [projectIsSelected]="projectIsSelected"
    (projectSelected)="projectSelected($event)"
  ></projects-page-list>
</div>
