<mat-card>
  <div *ngIf="isSignup">
    <h3>Create Account</h3>

    <button mat-stroked-button (click)="changeType('login')">
      Returning user?
    </button>
  </div>

  <div *ngIf="isLogin">
    <h3>Login</h3>
    <!-- <button size="small" mat-stroked-button (click)="changeType('signup')">
      New user?
    </button> -->
  </div>

  <div *ngIf="isPasswordReset">
    <h3>Reset Password</h3>
    <button size="small" mat-button (click)="changeType('login')">Back</button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field [color]="email.valid && 'primary'">
      <input
        matInput
        formControlName="email"
        type="email"
        placeholder="Email"
        autocomplete="off"
      />

      <mat-error *ngIf="email.invalid && email.dirty">
        You must enter a valid email address
      </mat-error>
    </mat-form-field>

    <mat-form-field [color]="email.valid && 'primary'" *ngIf="!isPasswordReset">
      <input
        matInput
        formControlName="password"
        type="password"
        placeholder="Password"
        autocomplete="off"
      />

      <mat-error *ngIf="password.invalid && password.dirty">
        Password must be between 8 and 30 characters long and contain lowercase
        letters, uppercase letters, numbers and special characters
      </mat-error>
    </mat-form-field>

    <mat-form-field
      [color]="passwordDoesMatch ? 'accent' : 'warn'"
      *ngIf="isSignup"
    >
      <input
        matInput
        formControlName="passwordConfirm"
        type="password"
        placeholder="Confirm password"
        autocomplete="off"
      />

      <mat-error *ngIf="passwordConfirm.touched && !passwordDoesMatch">
        Password does not match
      </mat-error>
    </mat-form-field>

    <mat-error class="server-error">{{ serverMessage }}</mat-error>

    <button
      *ngIf="isPasswordReset"
      mat-stroked-button
      type="submit"
      [disabled]="loading"
    >
      Send Reset Email
    </button>

    <button
      *ngIf="!isPasswordReset"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="form.invalid || !passwordDoesMatch || loading"
    >
      Submit
    </button>
  </form>
  <br />
  <button
    mat-button
    *ngIf="isLogin && !isPasswordReset"
    (click)="changeType('reset')"
  >
    Forgot password?
  </button>
</mat-card>
