import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  user$: Observable<User>;
  admin: Observable<boolean>;
  head: any;
  headerOpacity;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.admin = this.userService.isAdmin();
    this.user$ = this.userService.user$;
  }

  logout() {
    this.auth.logout();
  }
}
