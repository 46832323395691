import { Component, Input, Output, HostListener, EventEmitter } from '@angular/core';


@Component({
  selector: 'likes',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss']
})
export class LikeButtonComponent {
  @Input() likes: number;
  @Output() like = new EventEmitter();

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    event.stopPropagation();
  }

  likeItem() {
    this.like.emit('like');
  }
}
