// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAxKgjVbbTKr8z2262nTQfV_9k-nUJAuGU',
    authDomain: 'strategic-dev.firebaseapp.com',
    databaseURL: 'https://strategic-dev.firebaseio.com',
    projectId: 'strategic-dev',
    storageBucket: 'strategic-dev.appspot.com',
    messagingSenderId: '985494456054',
    appId: '1:985494456054:web:30851da43f462fb7da8ed7'
  }
};
