import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Page } from '../models/page';
import { SnackService } from './snack.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  pagesCollectionRef: AngularFirestoreCollection<Page>;
  pagesObservable: Observable<Page[]>;
  pageRef: AngularFirestoreDocument<Page>;

  constructor(
    private snack: SnackService,
    private afs: AngularFirestore,
    private router: Router
  ) {}

  ///// GET ALL PAGES /////************************************

  getPages(): Observable<Page[]> {
    // Make a reference to the Pages collection
    this.pagesCollectionRef = this.afs.collection('pages');

    // Get a 'SnapShot' Observable of the data
    return (this.pagesObservable = this.pagesCollectionRef
      .snapshotChanges()
      .pipe(
        map(arr => {
          return arr.map(snap => {
            const data = snap.payload.doc.data();
            const id = snap.payload.doc.id;
            return { pid: id, ...data };
          });
        })
      ));
  }

  ///// GET A SINGLE PAGE /////************************************

  getPage(pageId: string): Observable<Page> {
    // Reference the page
    this.pageRef = this.afs.collection('pages').doc(pageId);
    // Get an observable of the data
    return this.pageRef.snapshotChanges().pipe(
      map(snap => {
        const data = snap.payload.data();
        const id = snap.payload.id;
        return { pid: id, ...data };
      })
    );
  }

  ///// CREATE PAGE /////************************************

  createPage(pageInfo: Page) {
    // Reference
    this.pagesCollectionRef = this.afs.collection('pages');

    // Set
    this.pagesCollectionRef
      .add(pageInfo)
      .then(() => console.log('Page successfully added!'))
      .catch(error => console.error('Error adding page: ', error));

    this.router.navigate(['public/admin']);
    this.snack.passMessage('Page Updated', 3000, 'public/admin');
  }

  ///// UPDATE PAGE /////************************************

  updatePage(pageId: string, pageData: Page) {
    // Reference
    this.pageRef = this.afs.collection('pages').doc(pageId);
    const updatedPage = pageData;
    this.pageRef
      .update(updatedPage)
      .then(() => console.log('Page successfully updated!'))
      .catch(error => console.error('Error updating page: ', error));

    this.router.navigate(['public/admin']);
    this.snack.passMessage('Page Updated', 3000, 'public/admin');
  }

  ///// DELETE PAGE /////*************************************

  deletePage(pageId: string) {
    // Reference
    this.pageRef = this.afs.collection('pages').doc(pageId);

    // Delete the Page
    this.pageRef
      .delete()
      .then(() => console.log('Page successfully deleted!'))
      .catch(error => console.error('Error removing page: ', error));

    this.router.navigate(['public/admin']);
    this.snack.passMessage('Page Deleted', 3000, 'public/admin');
  }
}
