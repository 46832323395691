import { UploadImageService } from '../../services/upload-image.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../../services/pages.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Page } from '../../models/page';
import * as QuillNamespace from 'quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { htmlEditButton } from 'quill-html-edit-button';

@Component({
  selector: 'about-edit',
  templateUrl: './about-edit.component.html',
  styleUrls: ['./about-edit.component.scss']
})
export class AboutEditComponent implements OnInit {
  profileImage1: any = '../../../assets/images/person_edit.png';
  profileImage2: any = '../../../assets/images/person_edit.png';
  profileImage3: any = '../../../assets/images/person_edit.png';
  page: Observable<Page>;
  Quill: any = QuillNamespace;
  quill: any;

  pageForm = this.fb.group({
    heading: [''],
    // Director 1
    director1name: [''],
    director1title: [''],
    director1biog: [''],
    // Director 2
    director2name: [''],
    director2title: [''],
    director2biog: [''],
    // Director 3
    director3name: [''],
    director3title: [''],
    director3biog: ['']
  });

  constructor(
    private fb: FormBuilder,
    private pagesService: PagesService,
    private imageService: UploadImageService,
    private location: Location
  ) {
    this.Quill.register('modules/blotFormatter', BlotFormatter);
    this.Quill.register('modules/htmlEditButton', htmlEditButton);
  }

  async ngOnInit() {
    // Populate form with existing page data
    this.page = this.pagesService.getPage('about');
    this.page.subscribe((page) => {
      this.pageForm.get('heading').setValue(page.heading);
      // Director 1
      this.pageForm.get('director1name').setValue(page.director1name);
      this.pageForm.get('director1title').setValue(page.director1title);
      this.pageForm.get('director1biog').setValue(page.director1biog);
      if (page.director1pic) {
        this.profileImage1 = page.director1pic;
      }
      // Director 2
      this.pageForm.get('director2name').setValue(page.director2name);
      this.pageForm.get('director2title').setValue(page.director2title);
      this.pageForm.get('director2biog').setValue(page.director2biog);
      if (page.director2pic) {
        this.profileImage2 = page.director2pic;
      }
      // Director 3
      this.pageForm.get('director3name').setValue(page.director3name);
      this.pageForm.get('director3title').setValue(page.director3title);
      this.pageForm.get('director3biog').setValue(page.director3biog);
      if (page.director3pic) {
        this.profileImage3 = page.director3pic;
      }
    });
  }

  uploadProfile1(event) {
    const selectedFiles: FileList = event.target.files;
    const file = null || selectedFiles.item(0);
    if (file) this.imageService.uploadImage1(file);
  }

  uploadProfile2(event) {
    const selectedFiles: FileList = event.target.files;
    const file = null || selectedFiles.item(0);
    if (file) this.imageService.uploadImage2(file);
  }

  uploadProfile3(event) {
    const selectedFiles: FileList = event.target.files;
    const file = null || selectedFiles.item(0);
    if (file) this.imageService.uploadImage3(file);
  }

  back() {
    this.location.back();
  }

  onSubmit() {
    this.pagesService.updatePage('about', this.pageForm.value);
  }

  getPage(pageId: string) {
    this.pagesService.getPage(pageId);
    return this.page;
  }

  editPage(pageId: string, page: Page) {
    this.pagesService.updatePage(pageId, page);
  }
}
