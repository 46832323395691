// @Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firebase Imports
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';

// Modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AdminModule } from './admin/admin.module';
import { SplashModule } from './splash/splash.module';
import { PublicModule } from './public/public.module';

// Components
import { AppComponent } from './app.component';
import { NoAccessComponent } from './public/no-access/no-access.component';
import { NotFoundComponent } from './not-found/not-found.component';


// Services
import { PagesService } from './services/pages.service';

@NgModule({
  declarations: [
    AppComponent,
    NoAccessComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    FontAwesomeModule,
    SharedModule,
    AdminModule,
    ReactiveFormsModule,
    LayoutModule,
    PublicModule,
    SplashModule
  ],
  providers: [PagesService],
  bootstrap: [AppComponent]
})
export class AppModule {}
