import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../services/pages.service';
import { Observable } from 'rxjs';
import { Page } from '../../models/page';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {
  admin: Observable<boolean>;
  page: Observable<Page>;
  enquiriesRef: any;
  submitted: boolean;
  delivered: boolean;

  contactForm = this.fb.group({
    date: [new Date()],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    mobile: ['', Validators.required],
    email: ['', Validators.required],
    message: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private pagesService: PagesService
  ) {
    this.page = this.pagesService.getPage('contact');
    this.enquiriesRef = this.afs.collection('enquiries');
  }

  saveEnquiry(enquiryData: any) {
    this.enquiriesRef.add(enquiryData);
  }

  submitForm() {
    this.submitted = true;
    this.delivered = false;

    console.log(this.contactForm.value);
    this.saveEnquiry(this.contactForm.value);
    this.showSummissionStatus();
    setTimeout(() => {
      this.submitted = false;
      this.resetForm();
      this.delivered = false;
    }, 3000);
  }

  showSummissionStatus() {
    setTimeout(() => {
      this.delivered = true;
    }, 2000);
  }

  resetForm() {
    this.contactForm.reset();
    this.contactForm.markAsPristine();
    this.contactForm.markAsUntouched();
  }
}
