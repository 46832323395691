import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable, ɵConsole } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
  AngularFireStorageReference
} from '@angular/fire/storage';
import { Project } from '../models/project';
import { SnackService } from '../services/snack.service';
import { map, tap, finalize, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  projectsCollectionRef: AngularFirestoreCollection<Project>;
  projectsObservable: Observable<Project[]>;
  projectRef: AngularFirestoreDocument<Project>;
  task: AngularFireUploadTask;
  progressUploader: number;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  posterURL: Promise<any>;
  posterID;
  projectID;

  constructor(
    private snack: SnackService,
    private db: AngularFirestore,
    private router: Router,
    private storage: AngularFireStorage
  ) {}

  ///// UPLOAD IMAGE     /////************************************

  public uploadImage(file: File): [AngularFireStorageReference, AngularFireUploadTask, Observable<number>] {
    // The storage path
    const path = `projects/${file.name}`;

    // Reference to storage bucket
    const ref: AngularFireStorageReference = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, file)

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    return [ref, this.task, this.percentage];

    this.snapshot = this.task.snapshotChanges();

  //   return this.snapshot.pipe(
  //     mergeMap(async (document) => {
  //       const downloadURL = await ref.getDownloadURL().toPromise();
  //       console.log('Mark #3', downloadURL);
  //       // this.db.collection('projects').add({ downloadURL: downloadURL, path });
  //       return downloadURL;
  //     })
  //   );
  }

  ///// GET ALL PROJECTS /////************************************

  getProjects(): Observable<Project[]> {
    // Make a reference to the Projects collection
    this.projectsCollectionRef = this.db.collection('projects', (ref) =>
      ref.orderBy('date', 'desc')
    );
    // Get a 'SnapShot' Observable of the data
    return (this.projectsObservable = this.projectsCollectionRef
      .snapshotChanges()
      .pipe(
        map((arr) => {
          return arr.map((snap) => {
            const data = snap.payload.doc.data();
            const id = snap.payload.doc.id;
            return { pid: id, ...data };
          });
        })
      ));
  }

  ///// GET A SINGLE PROJECT /////************************************

  getProject(projectId: string): Observable<Project> {
    // Reference the project
    this.projectRef = this.db.collection('projects').doc(projectId);
    // Get an observable of the data
    return this.projectRef.snapshotChanges().pipe(
      map((snap) => {
        const data = snap.payload.data();
        const id = snap.payload.id;
        return { pid: id, ...data };
      })
    );
  }

  ///// CREATE PROJECT /////************************************

  async createProject(newProject: Project) {
    // Reference
    this.projectsCollectionRef = this.db.collection('projects');

    // Set
    this.projectsCollectionRef
      .add(newProject)
      .then((project) => {
        // Add the ID
        const data = { pid: project.id };
        this.projectsCollectionRef.doc(project.id).set(data, { merge: true });

        console.log('Project successfully added!');
      })
      .catch((error) => console.error('Error adding project: ', error));

    this.router.navigate(['public/admin']);
    this.snack.passMessage('Project Added', 3000, 'public/admin');
  }

  ///// UPDATE PROJECT /////************************************

  updateProject(projectId: string, projectData: Project) {
    // Reference
    this.projectRef = this.db.collection('projects').doc(projectId);

    // Update
    this.projectRef
      .update(projectData)
      .then(() => console.log('Document successfully updated!'))
      .catch((error) => console.error('Error updating document: ', error));

    this.router.navigate(['public/admin']);
    this.snack.passMessage('Project Updated', 3000, 'public/admin');
  }

  ///// DELETE PROJECT /////*************************************

  deleteProject(projectId: string) {
    // Reference
    this.projectRef = this.db.collection('projects').doc(projectId);

    // Delete the Project
    this.projectRef
      .delete()
      .then(() => console.log('Project successfully deleted!'))
      .catch((error) => console.error('Error removing project: ', error));

    this.router.navigate(['public/admin']);
    this.snack.passMessage('Project Deleted', 3000, 'public/admin');
  }
}
