import { UserService } from './../services/user.service';
import { SnackService } from './../services/snack.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  fireUser$: Observable<firebase.User>;
  serverMessage: string;
  redirectUrl: string;

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    private snack: SnackService
  ) {
    this.fireUser$ = this.afAuth.authState;
  }

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider).then(credential => {
      this.userService.updateUserData(credential.user);
    });
  }

  async signup(email: string, password: string) {
    try {
      const credential = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      return this.userService.updateUserData(credential.user);
    } catch (err) {
      this.snack.passMessage(err);
    }
  }

  async resetPassword(email: string) {
    await this.afAuth.sendPasswordResetEmail(email);
  }

  async login(email: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(email, password);
    if (this.userService.isAdmin) this.redirectUrl = 'public/admin';

    // Redirect the user
    this.router.navigate([this.redirectUrl]);
  }

  async logout() {
    await this.afAuth.signOut();
    this.router.navigate(['public/login']);
  }
}

