import { PagesService } from './../../services/pages.service';
import { Component, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../models/page';

@Component({
  selector: 'about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements AfterViewInit {
  admin: Observable<boolean>;
  page: Observable<Page>;
  profileImage1: any;
  profileImage2: any;
  profileImage3: any;

  constructor(private pagesService: PagesService) {
    this.page = this.pagesService.getPage('about');
  }

  ngAfterViewInit() {
    this.page.subscribe((page) => {
      if (page.director1pic) {
        this.profileImage1 = page.director1pic;
      } else {
        this.profileImage1 = '../../../assets/images/person_edit.png';
      }
      if (page.director2pic) {
        this.profileImage2 = page.director2pic;
      } else {
        this.profileImage2 = '../../../assets/images/person_edit.png';
      }
      if (page.director3pic) {
        this.profileImage3 = page.director3pic;
      } else {
        this.profileImage3 = '../../../assets/images/person_edit.png';
      }
    });
  }
}
