<div class="spacer"></div>
<!-- <uploader></uploader> -->
<div class="image-input-container">
  <div class="image-container">
    <img class="posterImage" [src]="posterImage" (click)="fileInput.click()" />
    <input
      #fileInput
      class="fileInput"
      (change)="uploadPoster($event)"
      required
      accept=".jpg"
      type="file"
    />
    <!-- PROGRESS BAR -->
    <div class="uploadProgress" *ngIf="percentage | async as pct">
      <progress [value]="pct" max="100"></progress>
      {{ pct | number }}%
    </div>
    <!-- FILE URL -->
  </div>
  <!-- <div class="info">
    <p>Poster URL: {{ posterImage }}</p>
  </div> -->
</div>

<div class="form-container">
  <form [formGroup]="projectForm">
    <!-- Youtube URL -->
    <mat-form-field appearance="outline">
      <mat-label>YouTube URL</mat-label>
      <input
        type="text"
        formControlName="youtubeURL"
        required
        matInput
        placeholder="Enter YouTube URL"
      />
    </mat-form-field>

    <!-- Title -->
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input
        type="text"
        formControlName="title"
        required
        matInput
        placeholder="Enter title"
      />
    </mat-form-field>

    <!-- Synopsis -->
    <mat-form-field appearance="outline">
      <mat-label>Synopsis</mat-label>
      <textarea
        formControlName="synopsis"
        required
        matInput
        placeholder="Enter Synopsis"
      ></textarea>
    </mat-form-field>

    <!-- Directors -->
    <mat-form-field appearance="outline">
      <mat-label>Directors</mat-label>
      <input
        type="text"
        formControlName="directors"
        required
        matInput
        placeholder="Enter Directors"
      />
    </mat-form-field>

    <!-- Writers -->
    <mat-form-field appearance="outline">
      <mat-label>Writers</mat-label>
      <input
        type="text"
        formControlName="writers"
        required
        matInput
        placeholder="Enter Writers"
      />
    </mat-form-field>

    <!-- Stars -->
    <mat-form-field appearance="outline">
      <mat-label>Stars</mat-label>
      <input
        type="text"
        formControlName="stars"
        required
        matInput
        placeholder="Enter Stars"
      />
    </mat-form-field>

    <div class="buttons">
      <div class="spacer"></div>

      <!-- Submit Button -->
      <button
        type="button"
        mat-raised-button
        color="accent"
        (click)="onSubmit()"
      >
        Save
      </button>

      <button
        class="cancel"
        mat-raised-button
        color="warn"
        [routerLink]="['/public/admin']"
      >
        Cancel
      </button>
      <div class="spacer"></div>
    </div>
  </form>
</div>
<div class="spacer"></div>
