import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../../services/pages.service';
import { Observable, Subject } from 'rxjs';
import { Page } from '../../models/page';
import * as QuillNamespace from 'quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { htmlEditButton } from 'quill-html-edit-button';
import { Project } from 'src/app/models/project';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'projects-edit',
  templateUrl: './projects-edit.component.html',
  styleUrls: ['./projects-edit.component.scss']
})
export class ProjectsEditComponent implements OnInit, OnDestroy, OnChanges {
  private ngUnsubscribe = new Subject();
  projects$: Observable<Project[]>;
  project: Project;
  admin: Observable<boolean>;
  clickedProject: Project;
  userIsAdmin: boolean;
  page: Observable<Page>;
  currentURL: string;
  pageId: string;
  Quill: any = QuillNamespace;
  quill: any;
  projectsVisibility$: Observable<boolean>;
  showButton: boolean;
  pageForm = this.fb.group({
    heading: [''],
    content: ['']
  });

  constructor(
    private fb: FormBuilder,
    private pagesService: PagesService,
    private projectsService: ProjectsService,
    private dataService: DataService,
    private userService: UserService,
    private location: Location,
    private router: Router
  ) {
    this.Quill.register('modules/blotFormatter', BlotFormatter);
    this.Quill.register('modules/htmlEditButton', htmlEditButton);
    this.page = this.pagesService.getPage('projects');
    this.admin = this.userService.isAdmin();

    // Get the current URL to determine if Add New Project Button is displayed
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        event.url === '/public/admin'
          ? (this.showButton = true)
          : (this.showButton = false);
      });
  }

  async ngOnInit() {
    // Populate form with existing page data
    this.projects$ = this.projectsService.getProjects();
    this.dataService.inputData = this.projects$;
    this.dataService.inputData.subscribe(
      (data: any) => {
        this.dataService.nextData(data);
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.userService
      .isAdmin()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((a) => (this.userIsAdmin = a.valueOf()));
    this.page.subscribe((page) => {
      this.pageForm.get('heading').setValue(page.heading);
      this.pageForm.get('content').setValue(page.content);
    });
  }

  ngOnChanges() {}

  back() {
    this.location.back();
  }

  onSubmit() {
    this.pagesService.updatePage('projects', this.pageForm.value);
  }

  getPage(pageId: string) {
    this.pagesService.getPage(pageId);
    return this.page;
  }

  editPage(pageId: string, page: Page) {
    this.pagesService.updatePage(pageId, page);
  }

  ngOnDestroy() {
    // this.userService.isAdmin().subscribe(a => (this.userIsAdmin = a.valueOf())).unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
