import { Component, OnInit } from '@angular/core';
import { PagesService } from './../../services/pages.service';
import { Page } from '../../models/page';
import { Observable } from 'rxjs';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  admin: Observable<boolean>;
  page: Observable<Page>;

  constructor(private pagesService: PagesService) {
    this.page = this.pagesService.getPage('home');
  }

  ngOnInit(): void {}
}
