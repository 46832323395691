import { ProjectsService } from './../../../services/projects.service';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { Project } from 'src/app/models/project';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @Input() project: Project;
  @Input() projectIsSelected: boolean;
  projectRef: AngularFirestoreDocument<Project>;
  project$: Observable<Project>;
  admin: Observable<boolean>;


  constructor(
    public dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private projectsService: ProjectsService
  ) {
    this.admin = this.userService.isAdmin();
  }

  ngOnInit(): void {}

  openDialog() {
    console.log('Dialogue Opened');
    this.dialog.open(ModalComponent, {
      data: this.project
    });
  }

  handleEdit(projectId: string) {
    this.router.navigate(['public/admin', projectId]);
  }

  handleDelete(projectId: string) {
    this.projectsService.deleteProject(projectId);
  }
}
