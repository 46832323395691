<div class="container">
  <h1>Admin Console</h1>
  <mat-tab-group animationDuration="0ms" mat-align-tabs="start">
    <mat-tab label="Projects">
      <projects-edit></projects-edit>
    </mat-tab>
    <mat-tab label="Home">
      <home-edit></home-edit>
    </mat-tab>
    <mat-tab label="About">
      <about-edit></about-edit>
    </mat-tab>
    <mat-tab label="Media">
      <media-edit></media-edit>
    </mat-tab>
    <mat-tab label="Contact">
      <contact-edit></contact-edit>
    </mat-tab>
  </mat-tab-group>
</div>
