import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';

import { EmailLoginComponent } from './email-login/email-login.component';
import { SharedModule } from './../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { MediaPageComponent } from './media-page/media-page.component';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { ProjectsPageListComponent } from './projects-page/projects-page-list/projects-page-list.component';
import { ProjectComponent } from './projects-page/project/project.component';
import { ContactPageComponent } from './contact-page/contact-page.component';

import { PublicComponent } from './public.component';

@NgModule({
  declarations: [
    PublicComponent,
    HomePageComponent,
    AboutPageComponent,
    MediaPageComponent,
    ProjectsPageComponent,
    ProjectsPageListComponent,
    ProjectComponent,
    ContactPageComponent,
    LoginComponent,
    EmailLoginComponent,
  ],
  imports: [CommonModule, PublicRoutingModule, SharedModule]
})
export class PublicModule {}
