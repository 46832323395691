import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss']
})
export class EmailLoginComponent implements OnInit {
  form: FormGroup;

  type: 'login' | 'signup' | 'reset' = 'login';
  loading = false;

  serverMessage: string;

  constructor(private auth: AuthService, private fb: FormBuilder) {
    this.serverMessage = auth.serverMessage;
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).+?$/),
          Validators.minLength(8),
          Validators.maxLength(30)
        ]
      ],
      passwordConfirm: ['']
    });
  }

  changeType(val) {
    this.type = val;
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isSignup() {
    return this.type === 'signup';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }

  get passwordConfirm() {
    return this.form.get('passwordConfirm');
  }

  get passwordDoesMatch() {
    if (this.type !== 'signup') {
      return true;
    } else {
      return this.password.value === this.passwordConfirm.value;
    }
  }

  onSubmit() {
    this.loading = true;

    const email = this.email.value;
    const password = this.password.value;

    if (this.isLogin) {
      this.auth.login(email, password);
    }
    if (this.isSignup) {
      try {
        this.auth.signup(email, password);
      } catch (err) {
        this.serverMessage = err;
      }
    }
    if (this.isPasswordReset) {
      this.auth.resetPassword(email);
      this.serverMessage = 'Check your email';
    }

    this.loading = false;
  }
}
