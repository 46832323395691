<div class="form-container">
  <form [formGroup]="pageForm">
    <!-- Headline -->

    <label for="heading">Heading</label>
    <input
      type="text"
      formControlName="heading"
      placeholder="Enter heading.."
    />

    <!-- Content -->

    <label for="content">Subject</label>
    <quill-editor
      formControlName="content"
      placeholder="Enter Page Content here..."
    ></quill-editor>
    <!-- <div
      formControlName="content"
      placeholder="Enter Page Content here..."
      [froalaEditor]
    ></div> -->

    <!-- Submit Button -->

    <button type="button" mat-raised-button color="accent" (click)="onSubmit()">
      Save
    </button>

    <!-- Cancel Button -->

    <button class="cancel" mat-raised-button color="warn" (click)="back()">
      Back
    </button>
  </form>
</div>
